import * as React from "react";
import { styled, makeStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useState } from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import config from "config";


function SwaggerUIPage() {

    return (
        <PageLayout>
             <div style={{textAlign: "center", marginTop: "20px"}}>
                    <a href="/" style={{ padding: "10px", border: "1px solid", backgroundColor: "#b4b4b9", color: "white", borderRadius: "5px", marginTop: "20px" }}> <b>Go To Main Page</b> </a>
                </div>
            
            <SwaggerUI url={config.api.BASE_URL+"/api/v3/api-docs"} />
        </PageLayout>
    );
}

export default SwaggerUIPage;
