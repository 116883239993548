import * as React from "react";
// Material Dashboard 2 React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

import { useState } from "react";
import AvalancheApiService from "services/AvalancheApiService";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import HeaderBg from '../../assets/images/header-bg.png'
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


function NormalizedAPi() {

    const [nodeInfo, setNodeInfo] = useState([]);
    const [avaScanStatics, setAvaScanStatics] = useState([]);
    const [validators, setValidators] = useState();
    const [blockchains, setBlockchains] = useState([]);
    const [blockchainStatus, setBlockchainStatus] = useState([]);
    const [status, setStatus] = useState([]);
    const [currentSupply, setCurrentSupply] = useState();
    const [totalStake, setTotalStake] = useState([]);
    const [allCurrentSupply, setAllCurrentSupply] = useState([]);
    const [rows, setRows] = useState([]);
    const [avgTPS24H, setAvgTPS24H] = useState(0);
    const [burnedFees, setBurnedFees] = useState(0);
    const [statBlocks, setStatBlocks] = useState([]);
    const [statTransaction, setStatTransaction] = useState([]);
    const [data, setData] = useState({
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [0, 10, 20],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    });
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const avalancheApiService = new AvalancheApiService();

    React.useEffect(() => {
        setBlockchains([]);
        console.log("history history history")
        console.log(history)
        // history.push("/")
    }, [])

    // React.useEffect(() => {

    // }, [statBlocks, statTransaction])


    React.useEffect(() => {
        // console.log("on Status Changed")
        // console.log(status)
        const statusArr = blockchains;
        statusArr.push(status);
        setBlockchains(statusArr);

    }, [status])

    React.useEffect(() => {
        // console.log("on Status Changed allCurrentSupply")
        // console.log(allCurrentSupply)
        const statusArr = nodeInfo;
        statusArr.push(allCurrentSupply);
        setNodeInfo(statusArr);

    }, [allCurrentSupply])

    React.useEffect(() => {
        avalancheApiService.getNodeInfo()
            .then(json => {

                json.res_object.map(node => {
                    const supply = node;
                    avalancheApiService.getCurrentSupplyByNodeId(node.nodeId)
                        .then(json => {
                            // console.log("Json of getCurrentSupplyByNodeId value is")
                            // console.log(json)
                            supply.currentSupply = json.res_object.supply;
                            // console.log(supply)
                            setAllCurrentSupply(supply)
                        })

                    avalancheApiService.getTotalStakedByNodeId(node.nodeId)
                        .then(json => {
                            // console.log("Json of getTotalStakedByNodeId value is")
                            // console.log(json)
                            supply.totalStake = json.res_object.stake;
                            // console.log(supply)
                            setAllCurrentSupply(supply)
                        })
                })
            })

        avalancheApiService.getAllBlockchains()
            .then(json => {
                // setBlockchains(json.res_object);
                // let statusArr = [];
                json.res_object.map(blockchain => {
                    const block = blockchain;
                    avalancheApiService.getBlockchainStatusByBlockchainId(blockchain.bid)
                        .then(json => {
                            block.status = json.res_object.status
                            setStatus(block);
                        })
                })
            })

        avalancheApiService.getAvaScanInfo()
            .then(json => {
                // console.log("getAvaScanInfo value is")
                // console.log(json)
                setAvaScanStatics(json.res_object);
            })
        avalancheApiService.getTransactionHistory()
            .then(json => {
                // console.log("getTransactionHistory getTransactionHistory")
                // console.log(json)
                // console.log(json[1].count/86400)

                setAvgTPS24H(json[1].count / 86400);
            })

        avalancheApiService.getBurnedFees24H()
            .then(json => {
                // console.log("getBurnedFees24H getBurnedFees24H")
                // console.log(json)
                let burnedFees = 0;
                json.map(fees => {
                    burnedFees = burnedFees + parseFloat(fees.burned_fees);
                })
                // console.log(burnedFees)
                setBurnedFees(burnedFees)

            })

        avalancheApiService.getTransactionalHistory()
            .then(json => {
                console.log("json of getTransactionHistory")
                console.log(json.res_object)

                const transArr = [];
                const dateArr = [];
                json.res_object.map(tran => {
                    console.log("req_format")
                    let date_moment=moment(tran.date).format("DD MMM ")
                    console.log(date_moment)
                    // let req_format=date_moment.format("DD MMM")
                    // console.log(req_format)
                    dateArr.push(date_moment)
                    transArr.push(tran.count)
                })


                const dataValue = {
                    labels: dateArr.reverse(),
                    datasets: [
                        {
                            label: 'DAILY AVALANCHE TRANSACTIONS',
                            data: transArr.reverse(),
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                    ],
                }

                setData(dataValue);
            })
        // getAvaxStatsAPI();
        getAvaxStatsAPI();
        // setInterval(getAvaxStatsAPI, 10000);


    }, []);

    const getAvaxStatsAPI = () => {
        setStatTransaction([]);
        setStatBlocks([]);
        avalancheApiService.getStatsTransactionAvax()
            .then(json => {
                console.log("getStatsTransactionAvax value is")
                console.log(json)
                // setAvaScanStatics(json.res_object);
                setStatTransaction(json);
            })

        avalancheApiService.getStatsBlocksAvax()
            .then(json => {
                console.log("getStatsBlocksAvax value is")
                console.log(json)
                setStatBlocks(json);
                // setAvaScanStatics(json.res_object);
            })
    }

    const convertToInternationalCurrencySystem = (labelValue) => {

        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

                    : Math.abs(Number(labelValue));

    }

    const renderHashID = (id) => {
        const length = id.length;
        const first = id.substring(0, 7);
        const last = id.substring((length - 7), length);
        return first + "..." + last;
    }

    const handleChange = (value) => {
        console.log("value value value");
        console.log(event.target.value);
        setSearchText(event.target.value);
    }

    const renderTransaction = (transaction) => {
        return transaction.length;
    }

    return (
        <PageLayout style={{ backgroundColor: "white" }}>
            <div style={{ backgroundColor: "white" }}>
                <div style={{ backgroundImage: `url(${HeaderBg})`, textAlign: "center", height: "350px" }}>
                    <br />
                    <br />
                    <p style={{ fontSize: "25px", color: "white" }}><b>Coindelta</b></p>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" style={{ backgroundColor: "#9a9afa", color: "white", width: "40%" }}>
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={searchText}
                            onChange={event => handleChange(event)}
                            endAdornment={<InputAdornment position="end" style={{ color: "white",cursor: "pointer" }}><a>Search</a></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'search',
                                'color': 'white'
                            }}
                            placeholder="Search Node Id"
                            style={{ color: "white", border: "1px solid white" }}
                        />
                        {/* <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText> */}
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                {/* <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                    <p style={{ textAlign: "left" }}><a href="/swagger"> go to swagger UI</a></p>
                </div> */}
                <div>
                    <div style={{ padding: "20px", backgroundColor: "#f2f2fa", border: "1px solid black", marginLeft: "40px", marginRight: "50px", marginTop: "-60px", borderRadius: "8px" }}>
                        <div style={{ marginBottom: "30px" }}>
                            <p style={{ textAlign: "center", color: "black" }}><span style={{ paddingBottom: "11px", borderBottom: "3px solid #b0b0b0", paddingLeft: "20px", paddingRight: "20px" }}><b>AVASCAN</b> <span style={{ color: "#b0b0b0" }} >Statics</span></span></p>
                        </div>

                        <Grid container spacing={2} style={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>AVG TPS (24H)</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avgTPS24H.toFixed(2)}</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Fees (24H)</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{burnedFees.toFixed(2)} AVAX</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Staking Rewards</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avaScanStatics.stakingRewards} %</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Burned Since Launch</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{convertToInternationalCurrencySystem(avaScanStatics.burnedSinceLaunch)} AVAX</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Blockchains</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avaScanStatics.blockchains}</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Validators</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avaScanStatics.validators}</b></p>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>

                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Staking Ratio</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avaScanStatics.stakingRatio} %</b></p>
                            </Grid>

                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Last Avg Tps 24H</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avaScanStatics.lastAvgTps24h}</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Market Cap By Circulating Supply</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{convertToInternationalCurrencySystem(avaScanStatics.marketcapByCirculatingSupply)} AVAX</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Market Cap By Total Supply</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{convertToInternationalCurrencySystem(avaScanStatics.marketcapByTotalSupply)} AVAX</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Circulating Supply</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{convertToInternationalCurrencySystem(avaScanStatics.circulatingSupply)} AVAX</b></p>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "left" }}>
                                <p><b>Last Transactions 24h</b></p>
                                <p style={{ marginTop: "10px", fontSize: "18px" }}><b>{avaScanStatics.lastTransactions24h}</b></p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ textAlign: "left", fontSize: "14px", marginTop: "20px" }}>


                        </Grid>
                    </div>

                    <div style={{ padding: "20px", backgroundColor: "#f2f2fa", border: "1px solid black", marginLeft: "40px", marginRight: "50px", marginTop: "20px", borderRadius: "8px" }}>
                        {/* <div style={{ marginBottom: "30px" }}>
                            <p style={{ textAlign: "center", color: "black" }}><span style={{ paddingBottom: "11px", borderBottom: "3px solid #b0b0b0", paddingLeft: "20px", paddingRight: "20px" }}><b>DAILY AVALANCHE </b> <span style={{ color: "#b0b0b0" }} >TRANSACTIONS</span></span></p>
                        </div> */}
                        <Line height={"30px"} options={options} data={data} title={"DAILY AVALANCHE TRANSACTIONS"} />

                    </div>

                    <div style={{ padding: "20px", marginLeft: "40px", marginRight: "50px", marginTop: "20px", borderRadius: "8px" }}>
                        {/* <div style={{ marginBottom: "30px" }}>
                            <p style={{ textAlign: "center", color: "black" }}><span style={{ paddingBottom: "11px", borderBottom: "3px solid #b0b0b0", paddingLeft: "20px", paddingRight: "20px" }}><b>DAILY AVALANCHE </b> <span style={{ color: "#b0b0b0" }} >TRANSACTIONS</span></span></p>
                        </div> */}
                        <Grid container spacing={2} style={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                <div style={{ backgroundColor: "#f2f2fa", border: "1px solid black", padding: "10px" }}>
                                    <p>LATEST BLOCKS | VERTICES</p>

                                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: "20px" }}>
                                        <TableContainer style={{ fontSize: "10px" }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead style={{ display: "table-row-group" }}>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: "12px" }}>CHAIN</TableCell>
                                                        <TableCell align="left" style={{ fontSize: "12px" }}>BLOCK | VERTEX</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>HASH</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>TRANSACTIONS</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>FEES</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {statBlocks && statBlocks.map(row => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ backgroundColor: "#f2f2fa" }}>
                                                                <TableCell >
                                                                    <p style={{ fontSize: ".75rem" }}> <b> C </b></p>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }} >
                                                                    <b>{row.number}</b>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }}>
                                                                    <b>{renderHashID(row.id)}</b>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem" }}>
                                                                    <p style={{ textAlign: "center" }}> <b>{renderTransaction(row.transactions)}</b></p>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }}>
                                                                    <b>{row.burnedFees}</b>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                            </Grid>

                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                <div style={{ backgroundColor: "#f2f2fa", border: "1px solid black", padding: "10px" }}>
                                    <p><b>LATEST TRANSACTIONS</b></p>

                                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: "20px" }}>
                                        <TableContainer style={{ fontSize: "10px" }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead style={{ display: "table-row-group" }}>
                                                    <TableRow style={{ borderBottom: "1px solid black" }}>
                                                        <TableCell style={{ fontSize: "12px" }}>CHAIN</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>HASH</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>FROM</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>TO</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "12px" }}>AMOUNT</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {statTransaction && statTransaction.map(row => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ backgroundColor: "#f2f2fa" }}>
                                                                <TableCell>
                                                                    <p style={{ fontSize: ".75rem", color: "#9a9afa" }}><b> C </b> </p>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }} >
                                                                    <b> {renderHashID(row.id)}</b>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }}>
                                                                    <b>  {row.from && row.from.id ? renderHashID(row.from.id) : ""}</b>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }}>
                                                                    <b> {row.to && row.to.id ? renderHashID(row.to.id) : ""}</b>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: ".75rem", color: "#9a9afa" }}>
                                                                    <p style={{ textAlign: "center" }}> <b> {row.value} </b>    </p>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                            </Grid>

                        </Grid>

                    </div>


                    {/* <div style={{ marginTop: "10px", padding: "20px", backgroundColor: "white" }}>
                        <div style={{ marginBottom: "30px" }}>
                            <p style={{ textAlign: "center", color: "black" }}><span style={{ paddingBottom: "11px", borderBottom: "3px solid #b0b0b0", paddingLeft: "20px", paddingRight: "20px" }}><b>Node</b> <span style={{ color: "#b0b0b0" }} >Info</span></span></p>
                        </div>
                        <Paper sx={{ width: '96%', overflow: 'hidden' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead style={{ display: "table-row-group" }}>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {nodeInfo
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />  
                        </Paper>
                    </div>


                    <div style={{ marginTop: "10px", padding: "20px", backgroundColor: "white" }}>
                        <div style={{ marginBottom: "30px" }}>
                            <p style={{ textAlign: "center", color: "black" }}><span style={{ paddingBottom: "11px", borderBottom: "3px solid #b0b0b0", paddingLeft: "20px", paddingRight: "20px" }}><b>Blockchains</b> <span style={{ color: "#b0b0b0" }} >List</span></span></p>
                        </div>
                        <Paper sx={{ width: '96%', overflow: 'hidden' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead style={{ display: "table-row-group" }}>
                                        <TableRow>
                                            {blockChainsColumns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {blockchains
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {blockChainsColumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />  
                        </Paper>
                    </div>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <a href="/swagger" style={{ padding: "10px", border: "1px solid", backgroundColor: "#b4b4b9", color: "white", borderRadius: "5px", marginTop: "20px" }}> <b>Go to Swagger UI</b> </a>
                    </div> */}
                </div>
            </div>

            <br />
            <br />
            <br />
            <br />
        </PageLayout>
    );
}

export default NormalizedAPi;
